import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function getApiKeys() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/apiKey`);
  return res.data;
}

export async function getApiKey(id) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/apiKey/${id}`);
  return res.data;
}

export async function addApiKey(description) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/apiKey`, {
    description: description,
  });
  return res.data;
}
