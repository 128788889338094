<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label="ID">
                                    <b-form-input v-model="apiKey.id" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Created At">
                                        <b-form-input v-model="apiKey.createdAt" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Updated At">
                                        <b-form-input v-model="apiKey.updatedAt" readonly></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-col>
                                <b-form-group label="Description">
                                    <b-form-input v-model="apiKey.description" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label="Secret">
                                    <b-form-input v-model="apiKey.secret" readonly></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { getApiKey } from "@/services/ApiKeyService";
import { BButton, BCard, BCardBody, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow } from 'bootstrap-vue';

export default {
    name: "Job",
    components: {
        BButton,
        BCard,
        BCardBody,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BRow
    },
    data() {
        return {
            apiKey: null
        }
    },
    async mounted() {
        this.apiKey = await getApiKey(this.$route.params.id);
    },
}
</script>